<template>
  <ContentCard>
    <EditColumn
      submitText="Add Group Members"
      title="Add Group Members"
      :error="errorMessage"
      @submit="handleSubmit"
      @cancel="transitionStatus('idle')"
    />
  </ContentCard>
</template>

<script>
// @ is an alias to /src
import EditColumn from './Components/EditColumn'
import ContentCard from '@/Common/ContentCard'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'AddGroupMember',
  components: {
    EditColumn,
    ContentCard,
  },
  computed: {
    ...mapState('groups/group/groupMembers', ['status', 'errorMessage']),
  },
  methods: {
    ...mapActions('groups/group/groupMembers', [
      'addGroupMembers',
      'transitionStatus',
    ]),
    async handleSubmit(r) {
      await this.addGroupMembers(r)
    },
  },
}
</script>
