<template>
  <fieldset>
    <legend class="mb-2 font-display1 font-light">{{ title }}</legend>
    <div
      v-for="(groupMember, i) in values"
      v-bind:key="groupMember.uid"
      class="flex items-center w-full mb-4"
    >
      <div>
        <ToznyInput
          name="key"
          v-model="values[i].clientId"
          :error="hasError(i)"
          float-label
          hide-float
          label="Client ID"
          id="clientId"
          class="flex-grow mr-4"
        />
      </div>
      <div class="flex items-center w-full mb-4">
        <label for="read">
          <input
            type="checkbox"
            id="read"
            class="flex-grow mr-4"
            value="ReadContent"
            v-model="values[i].capabilities.READ_CONTENT"
          />
          <span>Read Content</span>
        </label>

        <label for="share">
          <input
            type="checkbox"
            id="share"
            class="flex-grow mr-4"
            value="ShareContent"
            v-model="values[i].capabilities.SHARE_CONTENT"
          />
          <span>Share Content</span>
        </label>
        <label for="manage">
          <input
            type="checkbox"
            id="manage"
            class="flex-grow mr-4"
            value="ManageMembership"
            v-model="values[i].capabilities.MANAGE_MEMBERSHIP"
          />
          <span>Manage Membership</span>
        </label>
        <br />
      </div>
      <a
        herf="#!"
        @click.prevent="removeItem(i)"
        class="flex cursor-pointer no-underline text-gray-dark"
        :title="`remove '${groupMember.clientId}'`"
      >
        <span class="sr-only"
          >remove "{{ groupMember.clientId }}" from the data set</span
        >
        <i class="material-icons-outlined md-24 micon">remove</i>
      </a>
    </div>
    <a
      herf="#!"
      @click.prevent="addItem"
      class="inline-block h-6 cursor-pointer no-underline text-gray-dark"
      :title="`add group member to  ${title}`"
    >
      <span class="sr-only">add group member to {{ title }}</span>
      <i class="material-icons-outlined md-24 micon">add</i>
    </a>
  </fieldset>
</template>

<script>
import ToznyInput from '@/Common/ToznyInput'

let uid = 1
export default {
  name: 'ToznyKeyValue',
  components: {
    ToznyInput,
  },
  props: {
    title: String,
    value: {
      type: Array,
      default: () => [{ clientId: '', capabilities: [] }],
      validator: (groupMembers) =>
        groupMembers.every((groupMember) => {
          if (typeof groupMember.clientId !== 'string') {
            return false
          }
          return groupMember.capabilities.every(
            (capability) => typeof capability === 'string'
          )
        }),
    },
  },
  data() {
    return {
      values: this.toArray(this.value),
    }
  },
  computed: {
    errors() {
      const foundErrors = []
      const lookup = {}
      for (let i = 0; i < this.values.length; i++) {
        if (lookup[this.values[i].clientId]) {
          foundErrors.push({ index: i })
        } else {
          lookup[this.values[i].clientId] = true
        }
      }
      return foundErrors
    },
  },
  methods: {
    toArray(items) {
      return items
        .map((i) => Object.assign({}, i, { uid: uid++ }))
        .map((i) => {
          i.capabilities = {
            READ_CONTENT: i.capabilities.includes('READ_CONTENT'),
            SHARE_CONTENT: i.capabilities.includes('SHARE_CONTENT'),
            MANAGE_MEMBERSHIP: i.capabilities.includes('MANAGE_MEMBERSHIP'),
          }
          return i
        })
    },
    addItem() {
      this.values = [
        ...this.values,
        { clientId: '', capabilities: [], uid: uid++ },
      ]
    },
    removeItem(i) {
      const removed = [...this.values]
      removed.splice(i, 1)
      this.values = removed
    },
    hasError(i) {
      for (let err of this.errors) {
        if (err.index === i) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    values: {
      deep: true,
      handler: function (values) {
        const value = values.map((i) => ({
          clientId: i.clientId,
          capabilities: Object.keys(i.capabilities).filter(
            (capability) => i.capabilities[capability]
          ),
        }))
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style>
input {
  margin: 0rem;
}
</style>
