<template>
  <div class="flex flex-col h-full">
    <div class="p-4 pb-2 border-b-2 border-gray-light flex-grow-0">
      <h3 class="inline-block mr-2 font-display1 text-base text-gray-dark">
        {{ title }}
      </h3>
    </div>
    <div class="flex-shrink h-full min-h-0">
      <EditForm
        :submit-text="submitText"
        :groupMembers="groupMembers"
        :loading="loading"
        :error="error"
        @submit="(r) => $emit('submit', r)"
        @cancel="$emit('cancel')"
      />
    </div>
  </div>
</template>

<script>
import EditForm from './EditForm'
export default {
  name: 'EditColumn',
  components: {
    EditForm,
  },
  props: {
    title: String,
    loading: Boolean,
    error: String,
    submitText: String,
    groupMembers: Array,
  },
}
</script>
