<template>
  <ToznyForm
    :on-submit="handleSubmit"
    :loading="loading"
    class="h-full w-full flex flex-col justify-between compact"
  >
    <p
      v-if="this.formErrors !== ''"
      class="text-tiny text-error mb-0 px-4 py-2"
    >
      {{ formErrors }}
    </p>
    <div class="flex-shrink-0 flex-grow-0 pt-2 mb-4 px-4">
      <div>
        <AddMemberForm
          title="Client"
          id="newMembers"
          name="newMembers"
          v-model="newMembers"
          class="mb-4"
        />
      </div>
      <div class="flex flex-row-reverse items-center">
        <ToznyButton
          id="new-groupMember-submit"
          data-auto-id="new-groupMember-submit"
          class="m-0"
          :buttonText="submitText ? submitText : 'Submit'"
          :loading="loading"
        />
        <a
          href="#!"
          @click.prevent="$emit('cancel')"
          class="text-gray-darkest no-underline mr-4"
          >Cancel</a
        >
      </div>
    </div>
  </ToznyForm>
</template>

<script>
import ToznyForm from '@/Common/ToznyForm'
import ToznyButton from '@/Common/ToznyButton'
import AddMemberForm from './AddMemberForm'
export default {
  name: 'EditForm',
  components: {
    ToznyForm,
    ToznyButton,
    AddMemberForm,
  },
  props: {
    groupMembers: {
      type: Array,
      default: () => [{ clientId: '', capabilities: [] }],
      validator: (groupMembers) =>
        groupMembers.every((groupMember) => {
          if (typeof groupMember.clientId !== 'string') {
            return false
          }
          return groupMember.capabilities.every(
            (capability) => typeof capability === 'string'
          )
        }),
    },
    loading: Boolean,
    error: String,
    submitText: String,
  },
  data() {
    return {
      validationErrors: [],
      type: (() => {
        return Object.keys(this.groupMembers).length !== 0 &&
          typeof this.groupMembers === 'object'
          ? this.groupMembers
          : ''
      })(),
      newMembers: this.groupMembers.map((member) =>
        Object.assign({}, member, { capabilities: member.capabilities.slice() })
      ),
    }
  },
  computed: {
    formErrors() {
      const errors = [...this.validationErrors]
      if (this.error) {
        errors.push(this.error)
      }
      return errors.join(', ')
    },
  },
  methods: {
    handleSubmit() {
      this.validationErrors = []
      const errors = []
      if (Object.keys(this.newMembers).length === 0) {
        errors.push('data must have at least one valid entry in it')
      }
      if (errors.length > 0) {
        this.validationErrors = errors
        return
      }
      this.$emit('submit', {
        groupMembers: this.newMembers,
      })
    },
  },
}
</script>

<style>
.compact input {
  padding: 0.33em 0.25em;
  height: auto;
}
.compact fieldset div {
  margin-bottom: 0.25em;
}
.compact label,
.compact legend {
  font-size: 12px;
  margin-bottom: 0.5em;
}
.compact .float-label label {
  font-size: 16px;
}
</style>
